import { Box, Grid, Link, Text, Image } from '@chakra-ui/react';

function Footer() {
  return (
    <Box as="footer" bg="white" color="white" py={10}>
      <Grid templateColumns="repeat(3, 1fr)" gap={6} mb={8}>
        {/* Column 1: Logo */}
        <Box>
          <Image src="images/logo-base.png" alt="Quantum Brew Logo" boxSize="100px" />
        </Box>

        {/* Column 2: Privacy Policy Link */}
        <Box>
          <Text fontWeight="bold" mb={2}>Legal</Text>
          <Link href="/privacy-policy" color="teal.300">
            Privacy Policy
          </Link>
          <Link href="/privacy-policy" color="teal.300">
            Terms and conditions
          </Link>
        </Box>

        {/* Column 3: Contact Us Link */}
        <Box>
          <Text fontWeight="bold" mb={2}>Contact</Text>
          <Link href="mailto:craftsman@quantumbrew.beer" color="teal.300">
            Contact Us
          </Link>
        </Box>
      </Grid>

      {/* Copyright Row */}
      <Box textAlign="center">
        <Text>&copy; {new Date().getFullYear()} Quantum Brew. All rights reserved.</Text>
      </Box>
    </Box>
  );
}

export default Footer;
