// src/theme.js or src/theme/index.js
import { extendTheme } from "@chakra-ui/react";

const customTheme = extendTheme({
  fonts: {
    heading: `'Anton', sans-serif`,   // Custom heading font
    body: `'Nunito Sans', sans-serif`, // Custom body font
  },
  colors: {
    black: "#3D2B2A", // Customize this hex value as needed
    white: "#FFFFFF", // Customize this hex value as needed
  },
  styles: {
    global: {
      body: {
        //bg: "linear-gradient(to bottom, #f6efdb 0%, #f4f3ef 100%)", // Global background
        color: "#3D2B2A" // Global text color
      },
      "input, select, textarea": {
        fontFamily: `'Nunito Sans', sans-serif`, // Apply Nunito to form elements
      },
    },
  },
});

export default customTheme;