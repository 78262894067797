import React, { useState, useEffect } from 'react';
import {
  Box, Button, FormControl, FormLabel, Input, Select, Text, Textarea, Heading, VStack, HStack, useToast, SimpleGrid, IconButton
} from '@chakra-ui/react';
import { EditIcon, DeleteIcon } from '@chakra-ui/icons'; // Edit and delete icons
import { collection, getDocs, addDoc, updateDoc, doc } from 'firebase/firestore'; // Firestore functions
import { firestore } from '../firebaseConfig'; // Firebase config
import NavBar from './NavBar'; // NavBar component
import Footer from './Footer';

const AddRecipe = ({ recipeIdFromParams }) => {
  const [isEditing, setIsEditing] = useState(!recipeIdFromParams);
  const [recipeId, setRecipeId] = useState(recipeIdFromParams || null);
  const [recipeName, setRecipeName] = useState('');
  const [beerType, setBeerType] = useState('');
  const [description, setDescription] = useState('');
  const [beerTypes, setBeerTypes] = useState([]);
  const [ingredients, setIngredients] = useState([]);
  const [steps, setSteps] = useState([]);
  const [ingredientTypes, setIngredientTypes] = useState([]);
  const [stepTypes, setStepTypes] = useState([]);
  const [newIngredient, setNewIngredient] = useState({ type: '', description: '', amount: '' });
  const [newStep, setNewStep] = useState({ type: '', description: '' });

  // Additional state fields
  const [batchSize, setBatchSize] = useState('');
  const [mashTemp, setMashTemp] = useState('');
  const [mashTime, setMashTime] = useState('');
  const [boilTime, setBoilTime] = useState('');
  const [fermentationTemp, setFermentationTemp] = useState('');
  const [fermentationLength, setFermentationLength] = useState('');
  const [og, setOg] = useState('');
  const [fg, setFg] = useState('');
  const [abv, setAbv] = useState('');
  const [tastingNotes, setTastingNotes] = useState('');
  const [carbonation, setCarbonation] = useState('');

  const toast = useToast();

  useEffect(() => {
    const fetchBeerTypes = async () => {
      const querySnapshot = await getDocs(collection(firestore, 'beerTypes'));
      const beerTypesData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setBeerTypes(beerTypesData);
    };

    const fetchIngredientTypes = async () => {
      const querySnapshot = await getDocs(collection(firestore, 'ingredientTypes'));
      const ingredientTypesData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setIngredientTypes(ingredientTypesData);
    };

    const fetchStepTypes = async () => {
      const querySnapshot = await getDocs(collection(firestore, 'stepTypes'));
      const stepTypesData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setStepTypes(stepTypesData);
    };

    fetchBeerTypes();
    fetchIngredientTypes();
    fetchStepTypes();
  }, []);

  const handleSave = async () => {
    const calculatedAbv = ((og - fg) * 131.25).toFixed(2);
    setAbv(calculatedAbv);

    const recipeData = {
      recipeName,
      beerType,
      description,
      batchSize,
      mashTemp,
      mashTime,
      boilTime,
      fermentationTemp,
      fermentationLength,
      og,
      fg,
      abv,
      tastingNotes,
      carbonation,
      ingredients,
      steps,
    };

    if (recipeId) {
      await updateDoc(doc(firestore, 'recipes', recipeId), recipeData);
      toast({ title: 'Recipe updated.', status: 'success' });
    } else {
      const docRef = await addDoc(collection(firestore, 'recipes'), recipeData);
      setRecipeId(docRef.id);
      toast({ title: 'Recipe created.', status: 'success' });
    }

    setIsEditing(false);
  };

  const handleAddIngredient = () => {
    setIngredients([...ingredients, { ...newIngredient }]);
    setNewIngredient({ type: '', description: '', amount: '' });
  };

  const handleAddStep = () => {
    setSteps([...steps, { ...newStep }]);
    setNewStep({ type: '', description: '' });
  };

  const handleDeleteIngredient = (index) => {
    const updatedIngredients = ingredients.filter((_, i) => i !== index);
    setIngredients(updatedIngredients);
  };

  const handleDeleteStep = (index) => {
    const updatedSteps = steps.filter((_, i) => i !== index);
    setSteps(updatedSteps);
  };

  return (
    <Box>
      <NavBar />
      <Heading>{isEditing ? 'Create or Edit Recipe' : recipeName}</Heading>
      <IconButton
        icon={<EditIcon />}
        aria-label="Edit Recipe"
        position="absolute"
        top={5}
        right={5}
        onClick={() => setIsEditing(true)}
        visibility={isEditing ? 'hidden' : 'visible'}
      />
      <SimpleGrid columns={[1, 2]} spacing={10} mt={85}>
        <Box>
        <Heading fontFamily="Anton">Create a recipe</Heading>
          <FormControl>
            <FormLabel>Recipe Name</FormLabel>
            <Input value={recipeName} onChange={(e) => setRecipeName(e.target.value)} isReadOnly={!isEditing} />
          </FormControl>

          <FormControl mt={4}>
            <FormLabel>Beer Type</FormLabel>
            <Select value={beerType} onChange={(e) => setBeerType(e.target.value)} isDisabled={!isEditing}>
              {beerTypes.map((type) => (
                <option key={type.id} value={type.name}>
                  {type.name}
                </option>
              ))}
            </Select>
          </FormControl>

          <FormControl mt={4}>
            <FormLabel>Description</FormLabel>
            <Textarea value={description} onChange={(e) => setDescription(e.target.value)} isReadOnly={!isEditing} />
          </FormControl>

          {/* Additional Fields */}
          <FormControl mt={4}>
            <FormLabel>Batch Size</FormLabel>
            <Input value={batchSize} onChange={(e) => setBatchSize(e.target.value)} placeholder="Enter batch size (e.g., 5 gallons)" isReadOnly={!isEditing} />
          </FormControl>

          <FormControl mt={4}>
            <FormLabel>Mash Temperature</FormLabel>
            <Input value={mashTemp} onChange={(e) => setMashTemp(e.target.value)} placeholder="Enter mash temperature (°F)" isReadOnly={!isEditing} />
          </FormControl>

          <FormControl mt={4}>
            <FormLabel>Mash Time</FormLabel>
            <Input value={mashTime} onChange={(e) => setMashTime(e.target.value)} placeholder="Enter mash time (minutes)" isReadOnly={!isEditing} />
          </FormControl>

          <FormControl mt={4}>
            <FormLabel>Boil Time</FormLabel>
            <Input value={boilTime} onChange={(e) => setBoilTime(e.target.value)} placeholder="Enter boil time (minutes)" isReadOnly={!isEditing} />
          </FormControl>

          <FormControl mt={4}>
            <FormLabel>Fermentation Temperature</FormLabel>
            <Input value={fermentationTemp} onChange={(e) => setFermentationTemp(e.target.value)} placeholder="Enter fermentation temperature (°F)" isReadOnly={!isEditing} />
          </FormControl>

          <FormControl mt={4}>
            <FormLabel>Fermentation Length</FormLabel>
            <Input value={fermentationLength} onChange={(e) => setFermentationLength(e.target.value)} placeholder="Enter fermentation length (days)" isReadOnly={!isEditing} />
          </FormControl>

          <FormControl mt={4}>
            <FormLabel>Original Gravity (OG)</FormLabel>
            <Input value={og} onChange={(e) => setOg(e.target.value)} placeholder="Enter Original Gravity (e.g., 1.050)" isReadOnly={!isEditing} />
          </FormControl>

          <FormControl mt={4}>
            <FormLabel>Final Gravity (FG)</FormLabel>
            <Input value={fg} onChange={(e) => setFg(e.target.value)} placeholder="Enter Final Gravity (e.g., 1.010)" isReadOnly={!isEditing} />
          </FormControl>

          <FormControl mt={4}>
            <FormLabel>Tasting Notes</FormLabel>
            <Textarea value={tastingNotes} onChange={(e) => setTastingNotes(e.target.value)} placeholder="Enter tasting notes (e.g., flavor, aroma)" isReadOnly={!isEditing} />
          </FormControl>

          <FormControl mt={4}>
            <FormLabel>Carbonation</FormLabel>
            <Input value={carbonation} onChange={(e) => setCarbonation(e.target.value)} placeholder="Enter carbonation method (e.g., forced carbonation)" isReadOnly={!isEditing} />
          </FormControl>

          {/* Ingredients Section */}
          <Box mt={4}>
            <Heading size="md">Ingredients</Heading>
            <VStack spacing={4} align="start">
              {ingredients.map((ingredient, index) => (
                <HStack key={index} spacing={4}>
                  <Select
                    placeholder="Select ingredient type"
                    value={ingredient.type}
                    onChange={(e) => {
                      const updatedIngredients = [...ingredients];
                      updatedIngredients[index].type = e.target.value;
                      setIngredients(updatedIngredients);
                    }}
                    isReadOnly={!isEditing}
                  >
                    {ingredientTypes.map((type) => (
                      <option key={type.id} value={type.name}>
                        {type.name}
                      </option>
                    ))}
                  </Select>
                  <Input
                    value={ingredient.description}
                    placeholder="Ingredient description"
                    onChange={(e) => {
                      const updatedIngredients = [...ingredients];
                      updatedIngredients[index].description = e.target.value;
                      setIngredients(updatedIngredients);
                    }}
                    isReadOnly={!isEditing}
                  />
                  <Input
                    value={ingredient.amount}
                    placeholder="Amount"
                    onChange={(e) => {
                      const updatedIngredients = [...ingredients];
                      updatedIngredients[index].amount = e.target.value;
                      setIngredients(updatedIngredients);
                    }}
                    isReadOnly={!isEditing}
                  />
                  {isEditing && (
                    <IconButton
                      icon={<DeleteIcon />}
                      onClick={() => handleDeleteIngredient(index)}
                      aria-label="Delete ingredient"
                    />
                  )}
                </HStack>
              ))}
              {isEditing && (
                <Button mt={4} onClick={() => setIngredients([...ingredients, { ...newIngredient }])}>
                  Add Ingredient
                </Button>
              )}
            </VStack>
          </Box>

          {/* Steps Section */}
          <Box mt={4}>
            <Heading size="md">Steps</Heading>
            <VStack spacing={4} align="start">
              {steps.map((step, index) => (
                <HStack key={index} spacing={4}>
                  <Select
                    placeholder="Select step type"
                    value={step.type}
                    onChange={(e) => {
                      const updatedSteps = [...steps];
                      updatedSteps[index].type = e.target.value;
                      setSteps(updatedSteps);
                    }}
                    isReadOnly={!isEditing}
                  >
                    {stepTypes.map((type) => (
                      <option key={type.id} value={type.name}>
                        {type.name}
                      </option>
                    ))}
                  </Select>
                  <Textarea
                    placeholder="Step description"
                    value={step.description}
                    onChange={(e) => {
                      const updatedSteps = [...steps];
                      updatedSteps[index].description = e.target.value;
                      setSteps(updatedSteps);
                    }}
                    isReadOnly={!isEditing}
                  />
                  {isEditing && (
                    <IconButton
                      icon={<DeleteIcon />}
                      onClick={() => handleDeleteStep(index)}
                      aria-label="Delete step"
                    />
                  )}
                </HStack>
              ))}
              {isEditing && (
                <Button mt={4} onClick={() => setSteps([...steps, { ...newStep }])}>
                  Add Step
                </Button>
              )}
            </VStack>
          </Box>
        </Box>

        {/* Right Side with non-editable and update button */}
        <Box>
          <Button
            mt={4}
            colorScheme="blue"
            onClick={handleSave}
            position="absolute"
            top={105}
            right={5}
          >
            {recipeId ? 'Update Changes' : 'Create Recipe'}
          </Button>
        </Box>
      </SimpleGrid>
      <Footer />
    </Box>
  );
};

export default AddRecipe;
