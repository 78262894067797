import React, { useState } from 'react';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { Box, Button, FormControl, FormLabel, Input, VStack, Heading, Text, Center } from '@chakra-ui/react';
import { auth } from '../firebaseConfig'; // Make sure auth is imported from firebaseConfig

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate('/home');
    } catch (err) {
      setError('Invalid email or password');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Center height="100vh">
      <Box
        width="100%"
        maxW="560px"
        padding="8"
        boxShadow="lg"
        borderRadius="md"
        bg="white"
      >
        <Heading as="h1" size="lg" textAlign="center" mb={6}>
          Log In
        </Heading>

        <form onSubmit={handleLogin}>
          <VStack spacing={4}>
            {/* Email Input */}
            <FormControl isRequired>
              <FormLabel>Email</FormLabel>
              <Input
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </FormControl>

            {/* Password Input */}
            <FormControl isRequired>
              <FormLabel>Password</FormLabel>
              <Input
                type="password"
                placeholder="Enter your password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </FormControl>

            {/* Error Message */}
            {error && <Text color="red.500">{error}</Text>}

            {/* Submit Button */}
            <Button
              type="submit"
              colorScheme="teal"
              width="100%"
              isLoading={loading}
            >
              Log In
            </Button>
          </VStack>
        </form>
      </Box>
    </Center>
  );
};

export default Login;
